import React, { useEffect, useRef } from "react"
import { TitleUnderlineOne, TitleUnderlineTwo, TitleUnderlineThree } from "./titleUnderline"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import useNearScreen from "@hooks/useNearScreen"
import imageOneDesktop from "./images/imagesDesktop/imageOne.webp"
import imageTwoDesktop from "./images/imagesDesktop/imageTwo.webp"
import imageThreeDesktop from "./images/imagesDesktop/imageThree.webp"
import imageFourDesktop from "./images/imagesDesktop/imageFour.webp"
import imageOneMobile from "./images/imagesMobile/imageOne.webp"
import imageTwoMobile from "./images/imagesMobile/imageTwo.webp"
import imageThreeMobile from "./images/imagesMobile/imageThree.webp"
import imageFourMobile from "./images/imagesMobile/imageFour.webp"

const Experience = ({ location }) => {
  const { isNearScreen, fromRef } = useNearScreen({ distance: "10px" })
  const sliderRef = useRef()

  useEffect(() => {
    if (isNearScreen) sliderRef.current.slickPlay()
  }, [isNearScreen])

  const activateSliderTestimonies = {
    arrows: true,
    autoplay: false,
    // autoplay: true,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplaySpeed: 14000,
  }

  const data = [
    {
      title: "Mejora tiempos de ocupación",
      description: "Asegura una transición fluida entre llamadas, reduciendo tiempos muertos y aumentando la productividad al configurar tiempos de espera máximos y mínimos que garanticen una distribución equitativa de las llamadas de todo tu equipo.",
      image: imageOneMobile,
      background: "#D3F7FA"
    },
    {
      title: "Autoservicio en devolución de llamadas",
      description: "No pierdas leads potenciales de los clientes que no respondieron. Implementa un autoservicio con IVR para procesos de recalling. Mejora la experiencia del cliente permitiéndoles elegir el mejor momento para ser contactados.",
      image: imageTwoMobile,
      background: "#FBE6E5"
    },
    {
      title: "Seguridad en tus operaciones",
      description: "Protegemos la información generada en las interacciones y aseguramos que tus operaciones y los datos de tus clientes estén seguros de tus campañas con protección contra DDoS y certificaciones como ISO 27001, SOC 2 y GDPR.",
      image: imageFourMobile,
      background: "#EEEAFB"
    },
    {
      title: "Supervisa tus llamadas en tiempo real",
      description: "Mantén el control de la calidad y el rendimiento de tus campañas en tiempo real. Intervén cuando sea necesario y proporciona retroalimentación inmediata para mejorar continuamente. Asegura la excelencia en cada interacción con herramientas de supervisión y análisis avanzadas.",
      image:imageThreeMobile ,
      background: "#FBE6E5"
    },
  ]

  return (
    <section className="container-experience-voiceApi">
      <p className="container-experience-voiceApi-title">
      <TitleUnderlineOne underline="Eleva tus tasas " /> <TitleUnderlineTwo underline="de conversión" />  automatizando tus procesos y enfocándote en lo que realmente importa, conectar y convertir
            </p>

      {/* Contenedor de dos columnas */}
      <div className="container-experience-voiceApi-cardsTwo">
        {/* columna */}
        <div className="container-experience-voiceApi-cardsTwo-cards-column">
          <section className="container-experience-voiceApi-cardsTwo-card" style={{ background: "#EEEAFB", padding: "32px 0px 0px" }}>
            <section style={{padding: "0px 32px" }}>
              <p className="container-experience-voiceApi-cardsTwo-card-title">Mejora tiempos de ocupación</p>
              <p className="container-experience-voiceApi-cardsTwo-card-description">
                Asegura una transición fluida entre llamadas, reduciendo tiempos muertos y aumentando la productividad al configurar tiempos de espera máximos y mínimos que garanticen una distribución equitativa de las llamadas de todo tu equipo.
              </p>
            </section>
            <section>
              <img
                className="container-experience-voiceApi-cardsTwo-card-image"
                src={imageOneDesktop}
                alt="foto"
                loading="lazy"
              />
            </section>
          </section>
        </div>
        {/* columna */}
        <section className="container-experience-voiceApi-cardsTwo-card" style={{ background: "#FBE6E5", padding: "32px 30px 0px 0px" }}>
          <section style={{ padding: "0px 0px 0px 32px" }} >
            <p className="container-experience-voiceApi-cardsTwo-card-title">Autoservicio en devolución de llamadas</p>
            <p className="container-experience-voiceApi-cardsTwo-card-description">
              No pierdas leads potenciales de los clientes que no respondieron. Implementa un autoservicio con IVR para procesos de recalling. Mejora la experiencia del cliente permitiéndoles elegir el mejor momento para ser contactados.
            </p>
          </section>
          <section>
            <img
              className="container-experience-voiceApi-cardsTwo-card-image"
              src={imageTwoDesktop}
              alt="foto"
              loading="lazy"
            />
          </section>
        </section>
      </div>

      {/* Contenedor de dos columnas */}
      <div className="container-experience-voiceApi-cardsOne">
        {/* una sola fila */}
        <section className="container-experience-voiceApi-cardsOne-card" style={{ background: "#FBE6E5", padding: "32px 32px 0px" }}>
          <section >
            <p className="container-experience-voiceApi-cardsOne-card-title">Seguridad en tus operaciones</p>
            <p className="container-experience-voiceApi-cardsOne-card-description">
              Protegemos la información generada en las interacciones y aseguramos que tus operaciones y los datos de tus clientes estén seguros de tus campañas con protección contra DDoS y certificaciones como ISO 27001, SOC 2 y GDPR.
            </p>
          </section>
          <section>
            <img
              className="container-experience-voiceApi-cardsOne-card-image"
              src={imageThreeDesktop}
              alt="foto"
              loading="lazy"
            />
          </section>
        </section>
        {/* Dos filas */}
        <div className="container-experience-voiceApi-cardsOne-column">
          {/* columna */}
          <section className="container-experience-voiceApi-cardsOne-card" style={{ background: "#EEEAFB" }}>
            <section>
              <p className="container-experience-voiceApi-cardsOne-card-title">Supervisa tus llamadas en tiempo real</p>
              <p className="container-experience-voiceApi-cardsOne-card-description">
                Mantén el control de la calidad y el rendimiento de tus campañas en tiempo real. Intervén cuando sea necesario y proporciona retroalimentación inmediata para mejorar continuamente. Asegura la excelencia en cada interacción con herramientas de supervisión y análisis avanzadas.
              </p>
            </section>
            <section>
              <img
                className="container-experience-voiceApi-cardsOne-card-image"
                src={imageFourDesktop}
                alt="foto"
                loading="lazy"
              />
            </section>
          </section>
        </div>
      </div>

      <div className="container-experience-voiceApi-cards-mobile" ref={fromRef}>
        <Slider {...activateSliderTestimonies} ref={sliderRef}>
          {data.map((elemento, i) => {
            return (
              <section key={i}>
                <div className="container-experience-voiceApi-cards-mobile-card" style={{ background: elemento.background }}>
                  <section>
                    <p className="container-experience-voiceApi-cards-mobile-card-title">{elemento.title}</p>
                    <p className="container-experience-voiceApi-cards-mobile-card-description">
                      {elemento.description}
                    </p>
                  </section>
                  <section>
                    <img
                      className="container-experience-voiceApi-cards-mobile-card-image"
                      src={elemento.image}
                      alt="foto"
                      loading="lazy"
                    />
                  </section>
                </div>
              </section>
            )
          })}
        </Slider>
      </div>
    </section>
  )
}
export default Experience
