import React from "react"

export const TitleUnderlineTestimonyOne = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
      width="176" height="10" viewBox="0 0 176 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M32.6424 8.72465C28.5734 7.97829 2.12096 10.1013 0.605341 9.99622C-0.999874 9.75296 0.941317 3.89821 2.33001 2.96941C3.18115 2.4055 3.12142 2.81461 10.4606 2.4055C10.4606 2.4055 21.5254 1.80842 35.1287 1.11182C35.1287 1.11182 56.982 0.210661 87.0704 0.000575625C89.4372 -0.0160101 87.5632 0.332289 90.3481 0.210661C96.2836 -0.0381243 109.2 0.431804 113.695 0.232775C117.301 0.072447 118.077 0.0613902 121.519 0.365461C123.886 0.575547 143.746 1.09523 145.948 0.780103C146.486 0.702703 146.874 0.75246 146.867 0.879617C147.023 0.890674 160.037 1.64809 160.097 1.76419C160.186 1.89687 160.582 1.9798 160.963 1.91346C161.664 1.7863 175.738 2.17883 175.925 2.56583C176.462 3.67154 174.006 8.03911 172.281 9.05083C170.497 10.0957 161.844 8.26025 154.198 8.57538C118.54 6.94445 118.816 7.35909 112.858 7.57471C112.037 7.17112 108.797 8.03911 106.871 7.34804C106.064 7.05502 100.271 6.94445 98.9714 7.19324C98.6952 7.24852 96.6271 7.23746 93.6406 7.19876C92.1474 7.18218 90.4227 7.15453 88.5786 7.13242C86.1148 7.09925 67.4719 6.94998 65.135 7.59129C58.8485 6.94998 38.6228 8.33765 32.6424 8.72465Z" fill="#FFBA00" />
    </svg>
  </span>
)


export const TitleUnderlineTestimonyTwo = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
      width="124" height="10" viewBox="0 0 124 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22.9981 8.72465C20.1313 7.97829 1.49431 10.1013 0.42649 9.99622C-0.704457 9.75296 0.663201 3.89821 1.6416 2.96941C2.24127 2.4055 2.19918 2.81461 7.36998 2.4055C7.36998 2.4055 15.1656 1.80842 24.7497 1.11182C24.7497 1.11182 40.1464 0.210661 61.3451 0.000575625C63.0126 -0.0160101 61.6922 0.332289 63.6543 0.210661C67.8362 -0.0381243 76.9364 0.431804 80.103 0.232775C82.6437 0.072447 83.1908 0.0613902 85.6157 0.365461C87.2832 0.575547 101.275 1.09523 102.827 0.780103C103.206 0.702703 103.479 0.75246 103.474 0.879617C103.585 0.890674 112.753 1.64809 112.795 1.76419C112.858 1.89687 113.137 1.9798 113.405 1.91346C113.9 1.7863 123.815 2.17883 123.947 2.56583C124.326 3.67154 122.595 8.03911 121.38 9.05083C120.123 10.0957 114.026 8.26025 108.64 8.57538C83.5169 6.94445 83.7115 7.35909 79.5139 7.57471C78.9352 7.17112 76.6523 8.03911 75.2952 7.34804C74.7271 7.05502 70.6451 6.94445 69.7299 7.19324C69.5352 7.24852 68.0782 7.23746 65.9741 7.19876C64.922 7.18218 63.7069 7.15453 62.4076 7.13242C60.6718 7.09925 47.537 6.94998 45.8906 7.59129C41.4614 6.94998 27.2115 8.33765 22.9981 8.72465Z" fill="#FFBA00" />
    </svg>
  </span>
)
